<template>
    <b-container fluid class="about-company">
        <b-container class="banner">
            <img
                src="~@/assets/images/mock/abouts/banner-1.png"
                class="banner-img"
                alt=""
            />
            <div class="search-bar">
                <div class="title">{{ $t('organization') }}</div>
            </div>
        </b-container>
        <b-container fluid class="px-0">
            <h3 class="title-section">{{ $t('organizationalChart') }}</h3>
            <b-container fluid class="diagram p-0">
                <b-container class="box-diagram">
                    <div class="logo-diagram">
                        <div class="logo-circle">
                            <img
                                src="~@/assets/images/loader/circle.png"
                                alt=""
                                class="circle-bg"
                            />
                            <img
                                src="~@/assets/images/loader/logo.png"
                                alt=""
                                class="logo-b"
                            />
                        </div>
                        <div class="text-pif">
                            <div class="gradiant">Pi Investment</div>
                            <div class="normal">Fund</div>
                        </div>
                    </div>
                    <div class="body-diagram desktop">
                        <b-row>
                            <b-col cols="12" md="6" class="left">
                                <div class="box box-top">
                                    <div class="title single">
                                        {{ $t('shareholdersPartner') }}
                                    </div>
                                </div>
                                <div class="box box-bottom">
                                    <div class="title">
                                        {{ $t('fundManagement') }}
                                    </div>
                                    <div class="description">
                                        - {{ $t('lookingForSupervision') }}
                                    </div>
                                    <div class="description">
                                        - {{ $t('projectEvaluation') }}
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="12" md="6" class="right">
                                <div class="box box-1">
                                    <div class="title single">
                                        {{ $t('foundingPartner') }}
                                    </div>
                                </div>
                                <div class="box box-2">
                                    <div class="title">
                                        {{ $t('adminGroup') }}
                                    </div>
                                    <div class="description">
                                        - {{ $t('resourceManagement') }}
                                    </div>
                                    <div class="description">
                                        - {{ $t('capitalManagement') }}
                                    </div>
                                    <div class="description">
                                        - {{ $t('orientedDevelopment') }}
                                    </div>
                                </div>
                                <div class="box box-3">
                                    <div class="title">
                                        {{ $t('callingForCapital') }}
                                    </div>
                                    <div class="description">
                                        - {{ $t('fundraisingProduct') }}
                                    </div>
                                    <div class="description">
                                        - {{ $t('callingAgent') }}
                                    </div>
                                    <div class="description">
                                        - {{ $t('fundraisingMedia') }}
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="body-diagram mobile">
                        <div class="box box-top">
                            <div class="title single">
                                {{ $t('shareholdersPartner') }}
                            </div>
                        </div>
                        <div class="box box-1">
                            <div class="title single">
                                {{ $t('foundingPartner') }}
                            </div>
                            <div class="child-line"></div>
                        </div>
                        <div class="child-line-main"></div>
                        <div class="box box-2">
                            <div class="title">{{ $t('adminGroup') }}</div>
                            <div class="description">
                                - {{ $t('resourceManagement') }}
                            </div>
                            <div class="description">
                                - {{ $t('capitalManagement') }}
                            </div>
                            <div class="description">
                                - {{ $t('orientedDevelopment') }}
                            </div>
                        </div>
                        <div class="box box-3">
                            <div class="title">{{ $t('adminGroup') }}</div>
                            <div class="description">
                                - {{ $t('resourceManagement') }}
                            </div>
                            <div class="description">
                                - {{ $t('capitalManagement') }}
                            </div>
                            <div class="description">
                                - {{ $t('orientedDevelopment') }}
                            </div>
                        </div>
                        <div class="box box-bottom">
                            <div class="title">{{ $t('fundManagement') }}</div>
                            <div class="description">
                                - {{ $t('lookingForSupervision') }}
                            </div>
                            <div class="description">
                                - {{ $t('projectEvaluation') }}
                            </div>
                        </div>
                    </div>
                </b-container>
            </b-container>
            <b-container fluid class="section-operating">
                <h3 class="title-section">{{ $t('administrativeCouncil') }}</h3>
                <b-container class="team-list">
                    <div class="team">
                        <div class="img">
                            <img
                                src="~@/assets/images/mock/abouts/phi.png"
                                alt=""
                            />
                        </div>
                        <div class="name">Mr. Công Phi</div>
                        <div class="position">{{ $t('chairman') }}</div>
                    </div>

                    <div class="team">
                        <div class="img">
                            <img
                                src="~@/assets/images/mock/abouts/long.png"
                                alt=""
                            />
                        </div>
                        <div class="name">Mr. Dũng Long</div>
                        <div class="position">
                            {{ $t('strategicAdvisor') }}
                        </div>
                    </div>

                    <div class="team">
                        <div class="img">
                            <img
                                src="~@/assets/images/mock/abouts/huyen_trang.jpg"
                                alt=""
                                class="huyen_trang"
                            />
                        </div>
                        <div class="name">MRS. HUYỀN TRANG</div>
                        <div class="position">
                            {{ $t('generalDirector') }}
                        </div>
                    </div>
                </b-container>
                <h3 class="title-section text-uppercase">{{ $t('adminGroup') }}</h3>
                <b-container class="team-list">
                    <div class="team">
                        <div class="img">
                            <img
                                src="~@/assets/images/mock/abouts/an_khoi.png"
                                alt=""
                                class="huyen_trang"
                            />
                        </div>
                        <div class="name">Ms. An Khôi</div>
                        <div class="position" v-if="false">
                            Trưởng Phòng kinh doanh
                        </div>
                    </div>

                    <div class="team">
                        <div class="img">
                            <img
                                src="~@/assets/images/mock/abouts/phuong_nguyen.jpg"
                                alt=""
                                class="huyen_trang"
                            />
                        </div>
                        <div class="name">Ms. Nguyễn Phượng</div>
                        <div class="position" v-if="false">
                            Trưởng Phòng kinh doanh
                        </div>
                    </div>

                    <div class="team">
                        <div class="img">
                            <img
                                src="~@/assets/images/mock/abouts/quang_minh.jpg"
                                alt=""
                                class="quang_minh"
                            />
                        </div>
                        <div class="name">Mr. Quang Minh</div>
                        <div class="position" v-if="false">
                            Trưởng Phòng kinh doanh
                        </div>
                    </div>

                    <div class="team">
                        <div class="img">
                            <img
                                src="~@/assets/images/mock/abouts/thi_trang.png"
                                alt=""
                                class="huyen_trang"
                            />
                        </div>
                        <div class="name">Ms. Triệu Trang</div>
                        <div class="position" v-if="false">
                            Giám Đốc kinh doanh
                        </div>
                    </div>

                    <div class="team">
                        <div class="img">
                            <img
                                src="~@/assets/images/mock/abouts/pham_huong.jpg"
                                alt=""
                                class="huyen_trang"
                            />
                        </div>
                        <div class="name">Ms. Phạm Hương</div>
                        <div class="position" v-if="false">
                            Trưởng Phòng kinh doanh
                        </div>
                    </div>
                </b-container>
            </b-container>
            <b-container fluid class="section-team" v-if="false">
                <h3 class="title-section">
                    NHÓM GỌI VỐN VÀ PHÁT TRIỂN GỌI VỐN
                </h3>
                <b-container class="team-list">
                    <div class="team" v-for="i in 6" :key="`team-${i}`">
                        <div class="img">
                            <img
                                :src="
                                    require(`@/assets/images/mock/abouts/team-${i}.png`)
                                "
                                alt=""
                            />
                        </div>
                    </div>
                </b-container>
            </b-container>
        </b-container>
    </b-container>
</template>

<script>
export default {};
</script>

<style lang="scss">
.about-company {
    padding: 50px 0 0;
    .banner {
        position: relative;
        min-height: 300px;
        border-radius: 25px;
        @media (min-width: 1400px) {
            max-width: 1320px;
        }

        img.banner-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 25px;
        }
        .search-bar {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            height: max-content;
            width: max-content;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .title {
                font-family: 'Roboto', sans-serif;
                color: #fff;
                font-size: 35px;
                font-weight: 600;
                text-align: center;
                text-transform: uppercase;
                margin: 30px 0;
                @media (max-width: 991px) {
                    font-size: 26px;
                }
                @media (max-width: 767px) {
                    font-size: 20px;
                }
            }
        }
    }
    .title-section {
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 40px;
        color: #003189;
        margin: 50px 0;
        @media (max-width: 575px) {
            font-size: 30px;
        }
    }
    .diagram {
        background: {
            image: url(~@/assets/images/background/why.png);
            size: cover;
            position: center center;
        }

        .box-diagram {
            padding: 40px 0;
            .logo-diagram {
                .logo-circle {
                    width: 125px;
                    height: 125px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: auto;
                    .circle-bg {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        animation: circleRotate 4s linear infinite;
                        z-index: 0;
                    }
                    .logo-b {
                        height: 80%;
                        width: auto;
                        position: relative;
                        animation: logoLight 4s linear infinite;
                    }
                }
                .text-pif {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    .gradiant {
                        font-size: clamp(25px, 4vw, 42px);
                        background: -webkit-linear-gradient(
                            -19deg,
                            #237fff 0%,
                            #0930ff 100%
                        );
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-weight: 700;
                    }
                    .normal {
                        color: #232323;
                        font-weight: 600;
                        font-size: clamp(22px, 4vw, 35px);
                        line-height: 100%;
                        margin-left: 15px;
                    }
                }
            }
            .body-diagram {
                position: relative;
                // overflow: hidden;
                display: flex;
                justify-content: center;
                padding-top: 50px;
                &.desktop {
                    display: none;
                }
                &:after {
                    content: '';
                    width: calc(100% - 160px * 2);
                    height: 2px;
                    background: #0930ff;
                    position: absolute;
                    left: 160px;
                    right: 160px;
                    top: 15px;
                    margin: 0 auto;
                    @media (max-width: 767px) {
                        background: none;
                    }
                }
                &:before {
                    content: '';
                    width: calc(100% - 160px * 2);
                    height: 3px;
                    background: #0930ff;
                    position: absolute;
                    left: 160px;
                    right: 160px;
                    bottom: calc(150px / 2);
                    margin: 0 auto;
                }
                .row {
                    // overflow: hidden;
                    width: 100%;
                    min-height: 500px;
                }

                .box {
                    min-height: 150px;
                    width: 300px;
                    background: linear-gradient(
                        172deg,
                        #237fff 0%,
                        #0930ff 100%
                    );
                    border: 5px solid #fff;
                    text-decoration: none;
                    transition: all 0.5s;
                    color: #fff;

                    transition: all cubic-bezier(0.215, 0.61, 0.355, 1);
                    font-size: 18px;
                    letter-spacing: 0.7px;
                    box-shadow: 1px 2px 10px 2px
                        rgba($color: #000000, $alpha: 0.3);
                    border-radius: 25px;
                    text-align: left;
                    padding: 5px 15px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    .title {
                        font-size: 20px;
                        color: #fff;
                        text-align: left;
                        width: 100%;
                        &.single {
                            text-align: center;
                        }
                    }
                    .description {
                        font-size: 15px;
                        width: 100%;
                    }
                }

                .left {
                    padding: 0;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    position: relative;
                    &:before {
                        content: '';
                        position: absolute;
                        top: -35px;
                        left: 159px;
                        margin: auto;
                        height: 30px;
                        width: 2px;
                        background-color: #0930ff;
                    }
                    .box-bottom {
                        &:after {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: -15px;
                            margin: auto;
                            border-color: transparent #0930ff transparent
                                transparent;
                            border-style: solid;
                            border-width: 10px 10px 10px 10px;
                            height: 0px;
                            width: 0px;
                            z-index: 5;
                            content: '';
                        }
                    }
                }
                .right {
                    padding: 0;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-end;
                    position: relative;
                    &:after {
                        position: absolute;
                        top: -10px;
                        right: 150px;
                        margin: auto;
                        border-color: #0930ff transparent transparent
                            transparent;
                        border-style: solid;
                        border-width: 10px 10px 10px 10px;
                        height: 0px;
                        width: 0px;
                        content: '';
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        top: -35px;
                        right: 159px;
                        margin: auto;
                        height: 30px;
                        width: 2px;
                        background-color: #0930ff;
                    }
                    .box {
                        position: relative;
                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: -150px;
                            bottom: 0;
                            margin: auto;
                            height: 3px;
                            width: 150px;
                            background-color: #0930ff;
                            z-index: 0;
                        }
                        &:last-child {
                            &:after {
                                height: 350px;
                                width: 2px;
                                top: auto;
                                top: calc(-450px + 100px);
                            }
                        }
                    }
                }
            }
            @media (max-width: 991px) {
                max-width: calc(100% - 20px);
                .body-diagram {
                    .right {
                        .box {
                            &:after {
                                left: -50px;
                                width: 50px;
                            }
                            &:last-child {
                                &:after {
                                    height: 350px;
                                    width: 2px;
                                    top: auto;
                                    top: calc(-450px + 100px);
                                }
                            }
                        }
                    }
                }
            }
            @media (min-width: 768px) {
                .body-diagram.desktop {
                    display: flex;
                }
                .body-diagram.mobile {
                    display: none;
                }
            }
            @media (max-width: 767px) {
                .body-diagram.mobile {
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-end;
                    max-width: 500px;
                    margin: 0 auto;
                    &:after {
                        right: calc(300px);
                        top: -15px;
                        left: auto;
                        width: 78px;
                        height: 3px;
                    }
                    &:before {
                        left: auto;
                        right: calc(375px);
                        top: 108px;
                        bottom: auto;
                        width: 3px;
                        height: calc(100% - (120px * 3) - (15px * 2) - 80px);
                    }
                    .child-line-main {
                        content: '';
                        position: absolute;
                        background-color: #0930ff;
                        left: auto;
                        right: calc(375px);
                        top: auto;
                        bottom: calc(120px / 2 + 15px);
                        width: 3px;
                        height: calc(100% - (120px * 2) - (15px * 3) - 75px);
                    }
                    .box {
                        margin-bottom: 15px;
                        min-height: 120px;
                        .title {
                            font-size: 16px;
                        }
                        .description {
                            font-size: 14px;
                        }
                        &:after {
                            content: '';
                            right: (100%);
                            width: calc(30% - 5px);
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            height: 3px;
                            background: #0930ff;
                        }
                        &.box-1 {
                            .child-line {
                                right: 100%;
                                width: calc(30% - 5px);
                                position: absolute;
                                bottom: 10%;
                                margin: auto;
                                height: 3px;
                                background: #0930ff;
                            }
                            &:before {
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: -10px;
                                margin: auto;
                                border-color: transparent transparent
                                    transparent #0930ff;
                                border-style: solid;
                                border-width: 10px 10px 10px 10px;
                                height: 0px;
                                width: 0px;
                                z-index: 10;
                                content: '';
                            }
                        }
                        &.box-2,
                        &.box-3,
                        &.box-4,
                        &.box-bottom {
                            &:after {
                                right: 100%;
                                left: auto;
                                width: calc(30% - 5px);
                            }
                        }
                        &.box-bottom {
                            &:before {
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: -10px;
                                margin: auto;
                                border-color: transparent transparent
                                    transparent #0930ff;
                                border-style: solid;
                                border-width: 10px 10px 10px 10px;
                                height: 0px;
                                width: 0px;
                                z-index: 10;
                                content: '';
                            }
                        }
                    }
                }
            }
            @media (max-width: 413px) {
                .body-diagram.mobile {
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-end;
                    max-width: 100%;
                    margin: 0 auto;
                    &:after {
                        right: calc(200px);
                        top: -15px;
                        left: auto;
                        width: 78px;
                        height: 3px;
                    }
                    &:before {
                        left: auto;
                        right: calc(275px);
                        top: 108px;
                        bottom: auto;
                        width: 3px;
                        height: calc(100% - (120px * 3) - (15px * 3) - 80px);
                    }
                    .child-line-main {
                        content: '';
                        position: absolute;
                        background-color: #0930ff;
                        left: auto;
                        right: calc(275px);
                        top: auto;
                        bottom: calc(120px / 2 + 15px);
                        width: 3px;
                        height: calc(100% - (120px * 2) - (15px * 3) - 75px);
                    }
                    .box {
                        margin-bottom: 15px;
                        min-height: 120px;
                        width: 255px;
                        .title {
                            font-size: 16px;
                        }
                        .description {
                            font-size: 14px;
                        }
                        &:after {
                            content: '';
                            right: (100%);
                            width: 25px;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            height: 3px;
                            background: #0930ff;
                        }
                        &.box-1 {
                            .child-line {
                                right: 100%;
                                width: 25px;
                                position: absolute;
                                bottom: 10%;
                                margin: auto;
                                height: 3px;
                                background: #0930ff;
                            }
                            &:before {
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: -10px;
                                margin: auto;
                                border-color: transparent transparent
                                    transparent #0930ff;
                                border-style: solid;
                                border-width: 10px 10px 10px 10px;
                                height: 0px;
                                width: 0px;
                                z-index: 10;
                                content: '';
                            }
                        }
                        &.box-2,
                        &.box-3,
                        &.box-4,
                        &.box-bottom {
                            &:after {
                                left: auto;
                                right: (100%);
                                width: 25px;
                            }
                        }
                        &.box-bottom {
                            &:before {
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: -10px;
                                margin: auto;
                                border-color: transparent transparent
                                    transparent #0930ff;
                                border-style: solid;
                                border-width: 10px 10px 10px 10px;
                                height: 0px;
                                width: 0px;
                                z-index: 10;
                                content: '';
                            }
                        }
                    }
                }
            }
        }
    }
    .section-operating {
        background: {
            image: url(~@/assets/images/mock/abouts/division.png);
            size: cover;
            position: center center;
            color: #fff;
        }
        padding: 0 0 50px;
        overflow: hidden;
        .title-section {
            margin: 100px 0 55px;
        }
    }
    .section-team {
        background: {
            size: cover;
            position: center center;
            color: #f3f8ff;
        }
        padding: 30px 0 50px;
        overflow: hidden;
        .team-list {
            max-width: 1100px;
            margin: auto;
            .team {
                flex: 1 1 auto;
                min-width: 150px;
                max-width: 320px;
                @media (max-width: 767px) {
                    flex: 0 0 145px;
                    margin: 0;
                    min-width: unset;
                    max-width: unset;
                    width: auto;
                }
            }
        }
    }
    .team-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .team {
            max-width: 320px;
            margin: 15px;
            width: 100%;
            &:hover {
                .img {
                    transition: all 1s;
                    border: 5px solid #fff;
                    img {
                        transform: scale(1.2);
                        transition: all 1s;
                    }
                }
            }
            .img {
                // border-radius: 50%;
                position: relative;
                width: 300px;
                height: 300px;
                box-shadow: 1px 0px 15px 5px #00000044;
                overflow: hidden;
                padding: 5px;
                margin-bottom: 10px;
                --borderWidth: 150px;
                border-radius: var(--borderWidth);
                display: flex;
                justify-content: center;
                align-items: center;
                &:after {
                    content: '';
                    position: absolute;
                    top: calc(-1 * var(--borderWidth));
                    left: calc(-1 * var(--borderWidth));
                    height: calc(100% + var(--borderWidth) * 2);
                    width: calc(100% + var(--borderWidth) * 2);
                    background: linear-gradient(
                        60deg,
                        #237fff,
                        #0930ff,
                        #237fff,
                        #0930ff,
                        #5073b8,
                        #ffff,
                        #237fff,
                        #0930ff
                    );
                    border-radius: calc(2 * var(--borderWidth));
                    -webkit-animation: animatedgradient 3s ease alternate
                        infinite;
                    animation: animatedgradient 3s ease alternate infinite;
                    background-size: 300% 300%;
                    z-index: 2;
                }
                img {
                    max-width: calc(300px - 10px);
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    object-fit: cover;
                    object-position: center center;
                    height: calc(300px - 10px);
                    border-radius: 50%;
                    z-index: 3;
                    margin: auto;
                    &.huyen_trang {
                        object-fit: cover;
                        object-position: top center;
                    }
                    &.quang_minh {
                        object-fit: cover;
                        object-position: top right;
                    }
                }
            }
            .name {
                text-align: center;
                font-weight: 800;
                font-size: 27px;
                color: #003189;
                margin: 0px auto 5px;
                line-height: 120%;
                @media (max-width: 575px) {
                    font-size: 19px;
                }
            }
            .position {
                color: #232323;
                text-align: center;
                font-weight: 500;
            }
        }
    }
    @media (max-width: 575px) {
        .banner {
            min-height: 250px;
        }
    }
    @media (max-width: 425px) {
        .banner {
            max-width: calc(100% - 15px);
        }
    }
}
@keyframes circleRotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
</style>
